/*  */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: inherit;
    font-size: inherit;
    scroll-behavior: smooth;
}
body {
    background-color: #fff3e7;
    color: #222831;
    font-family: "DM Sans", sans-serif;
}
a {
    height: 170%;
    text-decoration: none;
}

.PrawaAGH, .LewaIS, .PrzerywanaSekcji, .TytulSekcji, .LinkiStrony,
.GlowneTechIkony, .ZobaczStrone div:nth-child(1), .GlowneTechnologie div:nth-child(1),
.PojemnikWszystkichOrbit, .ElementOrbity, .OtoczkaHeader, .PrawaOrbity, .Sekcja, 
.WRamce, .ZobaczStrone, .GlowneTechnologie {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Nagłówek */
.OtoczkaHeader {
    width: 100%;
    height: 100px;
    background-color: #1a1b20;
    overflow: hidden;
    top: 0;
    z-index: 20;
    position: -webkit-sticky;
    position: sticky;
}
.Header {
    overflow: hidden;
    width: 100%;
    max-width: 1400px;   
    padding-left: 102px;
    padding-right: 102px;
    background-color: #1a1b20;
    color: #EEE;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "DM Sans", sans-serif;
    font-size: clamp(10px, 1vw, 17px);
    font-weight: 400;
    /* text-shadow: .1px 0 0 currentColor; */
    letter-spacing: 2.8px;
}
.LogoContainer {
    height: 28px;
    width: 120px;
}
.LogoContainer img {
    height: 100%;
}
.NavOption {
    margin-left: clamp(10px, 2.2vw, 28px);
    margin-right: clamp(10px, 2.2vw, 28px);
}
.NavOption:hover {
    color: #dacfb5;   
}
.NavNumber {color: #FFDC88;}
.PojemnikZamknij, .MobileMenu {
    display: none;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Zawartosc {
    margin: 0;
    min-width: calc(100px - 170px);
    max-width: 1400px;
    padding-left: 85px;
    padding-right: 85px;
    padding-top: 60px;
    display: flex;
}
/* Pasek kontaktu */
.PasekKontaktu {
    margin-right: 85px;
    width: 90px;
    height: 60vh;
    position: -webkit-sticky;
    position: sticky;
    top: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.PasekKontaktu a {
    height: auto;
    text-decoration: none;
}
#MobilnyKontakt {
    display: none;
}
.Ikona {
    width: 38px;
    margin-bottom: 30px;
}
.LiniaPionowa {
    height: 80px;
    width: 1.5px;
    background-color: #222831;
}

.SekcjaGlowna {
    width: 100%;
}

/* Pierwsza strona */
.Witam, .Sekcja {
    font-family: "DM Sans", sans-serif;
    font-size: clamp(14px, 2vw, 31px);
    font-weight: bold;
    letter-spacing: 1.2px;
    margin-bottom: 20px;
}
.Witam {
    height: 12%;
}
.PolowniczyPojemnik {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 330px;
    font-family: "DM Sans", sans-serif;
    font-size: clamp(15px, 1.6vw, 28px);
    letter-spacing: .3px;
    line-height: 55px;
}
.PolPojOdstep {
    margin-top: 150px;
}

.PolowniczyLewa, .PolowniczyPrawa {
    height: 100%;
}
.LewaOrbity {
    width: 50%;
    font-size: clamp(.7rem, 1.15vw, 1.1rem);
}
.PrawaOrbity {
    width: 50%;
    margin-top: 5px;
}
.Claimer {
    position: absolute;
    top: 160px;
    font-size: 12.5px;
    letter-spacing: 1.2px;
    color: #6d6760;
}
.LewaAW {
    width: 52%;
}
.PrawaAW, .LewaPrzepisy, .PrawaPortfolio {
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
}
.PrawaAW {width: 48%}
.PojemnikEdukacja {
    display: flex;
    margin-top: 65px; 
    height: 350px;
    font-family: "DM Sans", sans-serif;
    font-size: clamp(15px, 1.6vw, 23px);
    letter-spacing: 1px;
}
.LewaAGH {
    width: 55%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.PrawaAGH {
    height: 100%;
    width: 45%;
}
.LewaAGH .Podsekcja {
    height: 25%;
}
.LewaAGH .Tekst {
    width: 100%;
    height: 75%;
    line-height: clamp(30px, 3.3vw, 50px);
    padding-top: 20px;

}
.PrawaAGH img {
    height: 100%;
    max-width: 100%;
}

.PojemnikIS {
    height: 280px;
    margin-top: 50px;
}
.LewaIS {
    width: 35%;
    height: 100%;
    overflow: hidden;
}
.PrawaIS {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 55px;
    width: calc(55% - 55px);
    height: 100%;
}
.PrawaIS .Podsekcja {
    height: 15%;
    width: 100%;
    line-height: 20px;
}
.PrawaIS .Tekst {
    width: 100%;
    height: 85%; 
    line-height: clamp(30px, 3.8vw, 46px);
}
.LewaIS img {
    min-height: 10%;
    height: 100%;
}

.LewaPrzepisy {
    width: 48%;
    margin-right: 1%;
}
.PrawaPrzepisy {width: 51%;}

.LewaPortfolio {
    width: 50%;
    margin-right: 3%;
}
.PrawaPortfolio {
    width: 47%;
}

.NazywamSie {
    height: 65%;
}
.Zapraszam {
    margin-top: 15px;
    width: 100%;
    height: 15%;
    font-size: clamp(.7rem, 1vw, 1rem);
    text-shadow: .7px 0 0 currentColor;
}
.Programista {
    position: relative;
    width: 35%;
    max-height: 100%;
    z-index: 0;
}
.Programista img {
    max-height: 100%;
}

.Przewin {
    font-size: clamp(.6rem, .9vw, .85rem);
    margin-top: 25px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 3px;
}
.Wdol {
    margin-top: 15px;
    text-shadow: .2px 0 0 currentColor;
}
.StrzalkaDol {
    text-shadow: .7px 0 0 currentColor;
    position: relative;
    top: -2.5px;
}
.LiniaDol {
    margin-top: 20px;
    height: 100px;
    width: 1.5px;
    background-image: linear-gradient(#212121,#fff);
    align-self: center;
    justify-self: center;
}

.Sekcja {
    margin-top: 80px;
    margin-bottom: 25px;
}
.PrzerywanaSekcji {
    width: calc(50% - 85px);
    height: 6px;
    background-image: url("../public/liniaSekcji.png");
    
    background-size: 100%;
}
.TytulSekcji {
    margin-left: 20px;
    margin-right: 20px;
}

.PC {
    width: 95%;
    margin: 0 auto;
}
.Telefon {
    width: 16%;
    position: absolute;
    left: 10%;
}
.Tablet {
    width: 34%;
    position: absolute;
    left: 57%;
}

.Podsekcja {
    height: 11%;
    color: #222831;
    font-family: "DM Sans", sans-serif;
    font-size: clamp(10px, 1.6vw, 25px);
    font-weight: bold;
    line-height: clamp( 10px, 3vw, 40px );
}
.LewaAGH .Podsekcja{
    line-height: clamp(35px, 3.2vw, 50px);
}
.Tekst {
    margin-top: 5px;
    font-size: clamp(10px, 1.1vw, 16px);
    line-height: clamp(35px, 3.2vw, 46px);
    height: 60%;
    letter-spacing: .1px;
}

.WRamce {
    margin-top: 2%;
    height: 25%;
    width: 94%;
    background-image: url("../public/ramka.png");
    background-size: 100% 100%;
}
.TekstLinki {
    margin-bottom: 6px;
}
.TekstTech {
    margin-bottom: 10px;
}
.ZobaczStrone, .GlowneTechnologie {
    flex-direction: column;
}
.LinkiStrony {
    height: 40px;
}
.ZobaczStrone {
    width: 45%;
    height: 100%;
}
.GlowneTechnologie {
    width: calc(55% - 2px);
    height: 100%;
}
.GlowneTechnologie img {
    margin-right: 17px;
}
.ZobaczStrone img {
    margin-top: 25px;
}
.LinkiStrony a:nth-child(1) {
    margin-right: 17px;
}
.GlowneTechnologie img:last-child, .ZobaczStrone a:last-child img {
    margin-right: 0;
}
.GlowneTechnologie img {height: 33px;}
.ZobaczStrone img {height: 31px;}

.GlowneTechIkony {
    height: 33px;
}
.Separator {
    width: 2px;
    height: 75%;
    background-color: #78726a;
}
.ZobaczStrone div:nth-child(1), .GlowneTechnologie div:nth-child(1) {
    height: 20px;
    font-size: clamp(5px, .8vw, 13px);
    font-weight: bold;
    letter-spacing: 1.7px;
}

.PojNarz, .PojUmiej {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "DM Sans", sans-serif;
    font-size: clamp(7px, 1.1vw, 17px);
    text-shadow: .2px 0 0 currentColor;
}
.UmiejNarz img {
    height: 60px;
    filter: invert(13%) sepia(20%) saturate(425%) hue-rotate(195deg) brightness(98%) contrast(98%);
    margin-bottom: 30px;
}
.Umiejetnosci, .Narzedzia {
    margin: 6.5vw auto;
    width: 80%;
    display: flex;
    flex-direction: row;
}
.Umiejetnosci {
    justify-content: space-between;
}
.Narzedzia {
    justify-content: center;
}
.Narzedzia div {
    margin-right: 55px;
}
.Narzedzia div:last-child {
    margin-right: 0;
}




.Formularz {
    display: flex;
    flex-direction: column;
    position: relative;
}
.PojemnikInputu {
    margin-top: 3px;
    position: relative;
    width: 100%;
}
.Etykieta {
    font-size: 16px;
    position: absolute;
    left: 25px;
    top: 42px;
    z-index: -1;
    transition: left .3s, top .3s, font-size .4s, color .3s;
}
.EtykietaFokus {
    position: absolute;
    left: 12px;
    top: 5px;
    font-size: .8rem;
    text-shadow: .2px 0 0 currentColor;
    letter-spacing: .5px;
    background-color: #fff3e7;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    z-index: 1;
    transition: left .2s, top .2s, color .2s, font-size .4s;
}

input[type=text], textarea, input[type=email], input[type=tel] {
    width: 100%;
    height: 75px;
    border: solid #8f857c 2.9px;
    border-radius: 5px;
    margin-top: 10px;
    padding-left: 25px;
    background-color: transparent;
}
textarea {
    padding-top: 30px;
    font-family: "DM Sans", sans-serif;
    letter-spacing: .5px;
}

.kontaktRelative {
    height: 75px;
    position: relative;
}
input[type=submit] {
    height: 75px;
    width: 18%;
    min-width: 180px;
    position: absolute;
    top: 8px; 
    right: 0; 
    background-color: #222831;
    color: #eee;
    cursor: pointer;
    letter-spacing: 1px;
    border-radius: 5px;
}
input[type=submit]:hover {
    background-color: #323a47;
}
.Poprawa {min-height: 40px;}

#wiadomosc {
    height: 300px;
}

.nicWysyla, .cosWysyla, .takWysyla, .nieWysyla {
    font-size: 1.2em;
    align-self: flex-end;
    margin-top: -10px;
}
.nicWysyla, .cosWysyla, .takWysyla, .nieWysyla {
    position: relative;
    left: calc(-23% - 30px );
    top: -27px;
}

.nicWysyla {color: white}
.cosWysyla {color: black;}
.takWysyla {color: #003c00}
.nieWysyla {color: darkred}

.SekcjaGlowna {
    overflow: hidden;
}

.IntersekcjaLewa, .IntersekcjaPrawa {
    opacity: 0;
    transition: 1s;
}
.IntersekcjaLewa {
    transform: translateX(-100px);
}
.IntersekcjaPrawa {
    transform: translateX(100px);
}
.IntersekcjaPrawa.Odslonieta, .IntersekcjaLewa.Odslonieta {
    opacity: 1;
    transform: translateX(0px);
    transition: 1s;
}

.Lapacz {
    content: "";
    min-height: 2px;
    width: 100%;
}






.PojemnikWszystkichOrbit {
    position: absolute;
    width: 35%;
    height: 100%;
    overflow: hidden;
}
.n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
    transform: rotate(120deg) scale(0.444,1.8);
    position: absolute;
}
.n8, .n9, .n10, .n11, .n12 {
    transform: rotate(240deg) scale(0.444,1.8);
    position: absolute;
}
.n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7  
, .n8, .n9, .n10, .n11, .n12 {
    -webkit-animation: indeksowanie 5s linear infinite;
    animation: indeksowanie 5s linear infinite;
}
.OrbitaUmiejetnosci, .OrbitaNarzedzia {
    width: 225px;
    height: 225px;
    border-radius: 50%;
}
.OrbitaNarzedzia {
    -webkit-animation: lewy-obrot 5s linear infinite;
    animation: lewy-obrot 5s linear infinite;
}
.OrbitaUmiejetnosci {
    -webkit-animation: prawy-obrot 5s linear infinite;
    animation: prawy-obrot 5s linear infinite;
}

.n1::after, .n3::after, .n5::after, .n7::after,
.n8::after, .n9::after, .n10::after, .n11::after, .n12::after{
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    height: 225px;
    width: 225px;
    border-radius: 50%;
    border-width: 5px;
    border-color: rgba(0,0,0,1) rgba(255,0,0,0) rgba(255,0,0,0);
    border-style: dashed;
}
.n1::after, .n3::after, .n5::after, .n7::after {
    -webkit-animation: prawy-obrot 5s linear infinite;
    animation: prawy-obrot 5s linear infinite;
}
.n8::after, .n9::after, .n10::after, .n11::after, .n12::after{
    -webkit-animation: lewy-obrot 5s linear infinite;
    animation: lewy-obrot 5s linear infinite;
}
.n1::after {animation-delay: -.35s;}
.n3::after {animation-delay: -1.6s;}
.n5::after {animation-delay: -2.85s;}
.n7::after {animation-delay: -4.1s;}

.n8::after {animation-delay: -1s;}
.n9::after {animation-delay: -2s;}
.n10::after {animation-delay: -3s;}
.n11::after {animation-delay: -4s;}
.n12::after {animation-delay: -.2s;}

.n1, .n1 .OrbitaUmiejetnosci, .n1 .OrbitaUmiejetnosci .ElementOrbity {
    animation-delay: -.6s;
}
.n2, .n2 .OrbitaUmiejetnosci, .n2 .OrbitaUmiejetnosci .ElementOrbity {
    animation-delay: -1.2s;
}
.n3, .n3 .OrbitaUmiejetnosci, .n3 .OrbitaUmiejetnosci .ElementOrbity {
    animation-delay: -1.8s;
}
.n4, .n4 .OrbitaUmiejetnosci, .n4 .OrbitaUmiejetnosci .ElementOrbity {
    animation-delay: -2.4s;
}
.n5, .n5 .OrbitaUmiejetnosci, .n5 .OrbitaUmiejetnosci .ElementOrbity {
    animation-delay: -3s;
}
.n6, .n6 .OrbitaUmiejetnosci, .n6 .OrbitaUmiejetnosci .ElementOrbity {
    animation-delay: -3.6s;
}
.n7, .n7 .OrbitaUmiejetnosci, .n7 .OrbitaUmiejetnosci .ElementOrbity {
    animation-delay: -4.2s;
}

.n8, .n8 .OrbitaNarzedzia, .n8 .OrbitaNarzedzia .ElementOrbity {
    animation-delay: -1s;
}
.n9, .n9 .OrbitaNarzedzia, .n9 .OrbitaNarzedzia .ElementOrbity {
    animation-delay: -2s;
}
.n10, .n10 .OrbitaNarzedzia, .n10 .OrbitaNarzedzia .ElementOrbity {
    animation-delay: -3s;
}
.n11, .n11 .OrbitaNarzedzia, .n11 .OrbitaNarzedzia .ElementOrbity {
    animation-delay: -4s;
}
.n12, .n12 .OrbitaNarzedzia, .n12 .OrbitaNarzedzia .ElementOrbity {
    animation-delay: -5s;
}

.PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
    transform: scale(4, 1) rotate(240deg);
}
.PojemOrbitaNarzedzia .ElementOrbityWnetrze {
    transform: scale(4, 1) rotate(120deg);
}

.ElementOrbityWnetrze img {height: 25px;}
.ElementOrbity {
    width: 40px;
    height: 40px;
}
.PojemOrbitaNarzedzia .ElementOrbity {
    -webkit-animation: prawy-obrot 5s linear infinite;
    animation: prawy-obrot 5s linear infinite; 
}
.PojemOrbitaUmiejetnosci .ElementOrbity {
    -webkit-animation: lewy-obrot 5s linear infinite;
    animation: lewy-obrot 5s linear infinite; 
}

@keyframes indeksowanie {
    0%{z-index: 10;}
    100%{z-index: -10;}
}
@keyframes prawy-obrot {
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
}
@keyframes lewy-obrot {
    0%{transform: rotate(0deg);}
    100%{transform: rotate(-360deg);}
}



@media screen and (min-width: 2200px) {
    .Programista {
        width: 25% !important;
    }
    .Umiejetnosci, .Narzedzia {
        margin: 60px auto;
    }
}
@media screen and (min-width: 1799px) {
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,2.3);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,2.3);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(5, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(5, 1) rotate(120deg);
    }
    .Zawartosc {
        max-width: 1800px;
    }
    .Ikona {
        width: 47px;
        margin-bottom: 32px;
    }
    .PojemnikOrbit { 
        margin-top: 60px;
        line-height: 60px;
    }
    .Witam {
        font-size: 40px
    }
    .LewaAGH .Tekst {
        line-height: 43px;
    }
    .Zapraszam {
        font-size: 1.65rem;
        /* margin-top: 60px; */
    }
    .Wdol {
        font-size: 1.15rem;
        margin-top: 105px;
    }  
    .Header {
        max-width: 1800px;
    }
    .OtoczkaHeader {
        height: 100px;
    }
    .NavOption {
        font-size: 21px;
    }
    .LogoContainer {
        height: 34px;
    }
    .Programista {
        width: 40%;
    }
    .PolowniczyPojemnik {
        height: 455px;
    }
    .PojemnikOrbit {
        height: 470px !important;
    }
    .Podsekcja {
        font-size: clamp(10px, 1.7vw, 34px);
    }
    .LewaPortfolio .KontrolaTekstu {
        line-height: 55px;
    }
    input[type=text], textarea, input[type=email], input[type=tel] {
        height: 87px;
    }
    .Etykieta {
        font-size: 19px;
        letter-spacing: .8px;
        left: 30px;
        top: 45px;
    }
    .EtykietaFokus {
        left: 15px
    }
    input[type=submit] {
        height: 80px;
        font-size: 1.1rem;
    }
    .cosWysyla, .nicWysyla, .nieWysyla, .takWysyla {
        font-size: 1.1em;
        top: -21px;
    }
}
@media screen and (min-width: 1300px) and (max-width: 1798px) {
    .PC {
        width: auto;
        height: 100%;
    }
    .Umiejetnosci, .Narzedzia {
        margin: 60px auto;
    }
    .PolowniczyPojemnik {
        line-height: 36px;
    }
    .Witam {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .Zapraszam {
        font-size: 1.05rem;
    }
    .Tekst {
        line-height: 34px;
    }
    .PrawaIS .Tekst {
        line-height: 45px;
    }
    .LewaPortfolio .Tekst {
        line-height: 48px;
    }
    .LewaAGH .Tekst {
        margin-top: 0px;
        line-height: 42px;
        height: 74%;
    }
    .PrawaIS {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .Sekcja {
        margin-top: 60px;
    }
    .PolowniczyPojemnik {
        font-size: 10px;
    }
    .ZobaczStrone div:nth-child(1), .GlowneTechnologie div:nth-child(1) {
        height: 18px;
        font-size: 11px;
        letter-spacing: 1.6px;
    }
    .ZobaczStrone img {
        height: 23px;
    }
    .ZobaczStrone img {
        margin-top: 22px;
    }
    .GlowneTechnologie img {
        height: 25px;
    }
}

@media screen and (max-width: 1499px) and (min-width: 1401) {
    .Sekcja {
        margin-top: 60px;
    }
    .LiniaDol {height: 60px;}
    .PolowniczyPojemnik {
        height: 320px;
        line-height: 50px;
    }
    .Programista {
        width: 45%;
    }
    .PolowniczyPojemnik {
        line-height: 34px;
    } 
}
@media screen and (max-width: 1400px) and (min-width: 1301px) {
    .PolowniczyPojemnik {
        line-height: 37px;
    }
    .Tekst {
        line-height: 34px;
    }
    .LewaPortfolio .Tekst {
        line-height: 48px;
    }   
    .LewaAGH .Tekst {
        line-height: 35px;
    }
}
@media screen and (max-width: 1300px) and (min-width: 1201px) {
    .PolowniczyPojemnik, .Tekst {
        line-height: 42px;
    }
    .UmiejNarz img {
        height: 55px;
    }
    .PrawaAW, .PrawaIS, .PrawaAGH {
        width: 50%;
    }
    .LewaAW, .LewaIS, .LewaAGH {
        width: 50%;
    }
    .Sekcja {
        margin-top: 40px;
    }
    .GlowneTechnologie img{height: 24px;}
    .ZobaczStrone img {height: 21px;}
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,1.4);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,1.4);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(3, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(3, 1) rotate(120deg);
    }
    .LewaAGH .Podsekcja {
        height: 20%;
    }
    .LewaAGH .Tekst {
        height: 80%;
    }
    .Zapraszam {
        font-size: 15px;
    }
    .PolowniczyPojemnik {
        line-height: 30px;
    }
    .Tekst {
        line-height: 34px;
    }
    .LewaPortfolio .Tekst {
        line-height: 34px;
    }   
    .LewaAGH .Tekst {
        line-height: 35px;
    }
    .Podsekcja {
        font-size: 18px;
    }
}
@media screen and (max-width: 1200px) {
    .PolowniczyPojemnik {
        height: 290px;
        line-height: 38px;
    }
    .Witam {
        font-size: 20px;
    }
    .Zapraszam {
        font-size: 15px;
    }
    .PolowniczyPojemnik {
        line-height: 27px;
    }
    .Tekst {
        line-height: 30px;
    }
    .LewaPortfolio .Tekst {
        line-height: 30px;
    }   
    .LewaAGH .Tekst {
        line-height: 31px;
    }
    .Podsekcja {
        font-size: 18px;
    }
    .ZobaczStrone div:nth-child(1), .GlowneTechnologie div:nth-child(1) {
        height: 10px;
        font-size: 9px;
        letter-spacing: 1.6px;
    }
    .ZobaczStrone img {
        height: 22px;
    }
    .ZobaczStrone img {
        margin-top: 22px;
    }
    .GlowneTechnologie img {
        height: 21px;
    }
}
@media screen and (max-width: 1100px) and (min-width: 1001px) {
    .PolowniczyPojemnik {height: 280px;}
    .GlowneTechnologie img{height: 21px;}
    .ZobaczStrone img {height: 19px;}
    .TekstTech {margin-bottom: 0;}
    .TekstLinki {
        margin-bottom: 0;
        margin-top: 5px;
    }
    .UmiejNarz img {
        height: 45px;
    }
    .PasekKontaktu {
        margin-right: 65px;
    }
    .Header, .Zawartosc {
        padding-left: 60px;
        padding-right: 60px;
    }
    .Etykieta {
        font-size: 12px;
    }
    .nicWysyla, .cosWysyla, .takWysyla, .nieWysyla {
        font-size: .9em;
    }
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,1.2);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,1.2);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(2.8, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(2.8, 1) rotate(120deg);
    }
    .n1::after, .n3::after, .n5::after, .n7::after,
    .n8::after, .n9::after, .n10::after, .n11::after, .n12::after{
        border-width: 3px;
    }
    .Programista {
        width: 45%;
    }
    .Umiejetnosci, .Narzedzia {
        margin: 4.5vw auto;
    }
    .PrawaAGH img {
        width: 100%;
        max-width: 400%;
        height: auto;
    }
    .LewaAGH .Podsekcja {
    height: 25%;
    }
    .LewaAGH .Tekst {
        width: 100%;
        height: 75%;
        line-height: clamp(30px, 3.3vw, 50px);
    }
    .LewaAGH .Podsekcja {
        height: 16%;
    }
    .LewaAGH .Tekst {
        height: 82%;
    }
    .Witam {
        font-size: 20px;
    }
    .Zapraszam {
        font-size: 14px;
    }
    .Tekst {
        line-height: 30px;
    }
    .LewaPortfolio .Tekst {
        line-height: 29px;
    }   
    .LewaAGH .Tekst {
        line-height: 36px;
    }
    .Podsekcja {
        font-size: 18px;
    }
    .PrawaAGH img {
        width: 70%;
        height: auto;
    }
}
@media screen and (width: 1024px) {
    .NazywamSie {
        line-height: 25px;
    }
    .Zapraszam {
        font-size: 13px;
    }
    .LewaAW .Tekst {
        line-height: 29px;
    }
    .LewaPortfolio .Tekst {
        line-height: 33px;
    }
    .LewaAGH .Tekst {
        line-height: 34px;
    }
    .PrawaPrzepisy .Tekst {
        line-height: 29px;
    }
}


@media screen and (max-width: 1000px) and (min-width: 901px) {
    .PolowniczyPojemnik {
        height: 250px;
        line-height: 30px;
    }
    .Witam {
        font-size: 15px;
    }
    .Zapraszam {
        font-size: 10px;
    }
    .Tekst {
        line-height: 30px;
    }
    .LewaPortfolio .Tekst {
        line-height: 29px;
    }   
    .LewaAGH .Tekst {
        line-height: 36px;
    }
    .Podsekcja {
        font-size: 18px;
    }
    .PrawaAGH img {
        width: 80%;
        height: auto;
    }
    .UmiejNarz img {
        height: 35px;
    }
}
@media screen and (max-width: 900px) and (min-width: 851px) {
    .PolowniczyPojemnik {height: 220px;}
    .UmiejNarz img {
        height: 35px;
    }
    .Header, .Zawartosc {
        padding-left: 50px;
        padding-right: 50px;
    }
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,1.1);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,1.1);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(2.5, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(2.5, 1) rotate(120deg);
    }
    .Witam {
        font-size: 15px;
    }
    .Zapraszam {
        font-size: 11px;
    }
    .Tekst {
        line-height: 30px;
    }
    .LewaAW .Tekst {
        line-height: 25px;
    }
    .PrawaPrzepisy .Tekst {
        line-height: 26px;
    }
    .LewaPortfolio .Tekst {
        line-height: 26px;
    }   
    .LewaAGH .Tekst {
        line-height: 31px;
    }
    .Podsekcja {
        font-size: 18px;
    }
    .PrawaAGH img {
        width: 80%;
        height: auto;
    }
    .ZobaczStrone div:nth-child(1), .GlowneTechnologie div:nth-child(1) {
        height: 10px;
        font-size: 7px;
        padding-top: 10px;
        letter-spacing: 1.6px;
    }
    .ZobaczStrone img {
        height: 17px;
    }
    .ZobaczStrone img {
        margin-top: 22px;
    }
    .GlowneTechnologie img {
        height: 17px;
    }
    .TekstTech {
        margin-bottom: 5px;
    }
}


/* scaling mobile */
@media screen and (max-width: 850px) {
    .UmiejNarz img {
        height: 30px;
    }
    .UmiejNarz .Sekcja {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    #FormularzLink {
        margin-top: 5px;
    }
    .LewaPortfolio {
        margin-right: 0;
    }
    /* Usuwa właściwości automatycznego skalowania przy przejściu na widok mobilny */
    span.textFitted {
        font-size: inherit;
    }

    .UmiejNarz img {
        margin-bottom: 20px;
    }
    .PojemnikEdukacja {
        height: 400px;
    }
    .PrawaIS {
        margin-left: 0;
    }
    .Zapraszam {
        font-size: clamp(.1rem, 2vw, 1rem);
    }
    .Header {
        overflow: visible;
        overflow-y: visible;
    }
    .OtoczkaHeader {
        height: 150px;
        overflow: visible;     
        overflow-x: clip;
    }
    .LogoContainer {
        height: 40px;
    }
    .MobileMenu img {
        width: 55px;
        height: 55px;
    }
    #MobilnyKontakt {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        right: 0;
        height: 250px;
        width: 100%;
        margin-right: 0;
    }
    .mobilnySeparatorKontaktu {
        min-width: 100%;
        min-height: 10%;
        display: block;
    }
    #MobilnyKontakt .Ikona {
        margin-bottom: 0;
        margin-right: 60px;
        width: 80px;
        height: auto;
    }
    #MobilnyKontakt .Ikona:nth-child(3), #MobilnyKontakt .Ikona:nth-child(6) {
        margin-right: 0;
    }
    #MobilnyKontakt {
        margin-top: 40px;
        padding-left: 120px;
        padding-right: 120px;
    }
    #PCKontakt {
        display: none;
    }
    .NavigationOptions a {
        height: auto;
        margin-bottom: 60px;
        margin: 30px;
    }
    .NavigationOptions {
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: space-around;
        align-items: center;
        height: 100vh;
        width: 80vw;
        z-index: 20;
        top: 0;
        right: -80vw;
        font-size: 35px;
        align-items: center;
        background-color: #272c33;
        transition: right 1s;
    }
    .NavOption {
        margin-left: auto;
        margin-right: auto;
    }
    .PojemnikZamknij, .MobileMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        filter: invert(91%) sepia(77%) saturate(32%) hue-rotate(277deg) brightness(116%) contrast(87%);
    }
    .PojemnikZamknij {
        padding-top: 40px;
        margin-bottom: 50px;
    }
    .PojemnikZamknij img {
        width: 85px;
        height: 85px;
    }
    .PCKontakt {
        display: none;
    }
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,1.6);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,1.6);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(3.5, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(3.5, 1) rotate(120deg);
    }
    .PolowniczyPojemnik, .PojemnikEdukacja {
        display: flex;
        flex-direction: column;
    }
    .PolowniczyLewa, .PolowniczyPrawa, .LewaAGH, .LewaAW, .LewaIS, .PrawaAGH, 
    .PrawaIS, .PrawaAW, .PojemnikWszystkichOrbit {
        width: 100%;
    }
    .LewaAGH .Podsekcja {
        height: 70px;
    }
    .PojemnikEdukacja {
        margin-top: 0;
    }

    .PolowniczyPojemnik {
        height: auto;
    }
    .PrawaOrbity {
        position: relative;
    }
    .Tekst, .NazywamSie, .Zapraszam, .Podsekcja {
        padding-top: 5px;
        font-size: clamp(12.5px, 2vw, 40px);
        line-height: clamp(26px, 4.5vw, 40px);
    }
    .Witam {
        font-size: clamp(18px, 2vw, 40px);
        margin-bottom: 6px;
    }
    .Podsekcja {
        font-size: clamp(17px, 2vw, 40px);
    }
    .PrawaPrzepisy .Podsekcja {
        margin-top: 20px;
    }
    .ZobaczStrone div:nth-child(1), .GlowneTechnologie div:nth-child(1) {
        font-size: clamp(8px, 1.5vw, 40px);
        line-height: clamp(15px, 4.5vw, 40px);  
    }
    .TekstLinki {
        margin-bottom: -3px;
    }
    .PojemnikWszystkichOrbit {
        position: relative;
        height: 350px;
    }
    .Programista {
        height: 80%;
        width: auto;
    }
    .Wdol {
        font-size: clamp(3px, 2vw, 12px);
        line-height: clamp(10px, 3.5vw, 30px);
    }
    .Przewin {
        display: none;
    }
    .PrzerywanaSekcji {
        width: 50%;
    }
    .WRamce {
        height: 90px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .ZobaczStrone div:nth-child(1), .GlowneTechnologie div:nth-child(1) {
        font-weight: bold;
        letter-spacing: 1.7px;
    }
    .PolPojOdstep {
        margin-top: 90px;
    }
    .PrawaAGH {
        height: 240px;
    }
    .PrawaAGH img {
        margin-top: 15px;
        margin-bottom: 15px;
        height: 190px;
        width: auto;
    }
    .PojemnikIS {
        height: auto;
    }
    .LewaIS {
        height: auto;
    }
    .LewaIS img {
        display: none;
    }
    .PrawaIS {
        height: auto;
    }
    .PrawaIS .Tekst {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .PrzerywanaSekcji {
        background-image: url("../public/liniaSekcji2.png");
        
    }
}
@media screen and (max-width: 750px) {
    #MobilnyKontakt .Ikona {
        margin-right: 40px;
        width: 65px;
    }
    .NavigationOptions a {
        margin: 20px;
    }
}
@media screen and (max-width: 650px) {
    .UmiejNarz img {
        margin-bottom: 15px;
    }
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,1.4);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,1.4);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(3, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(3, 1) rotate(120deg);
    }
    .UmiejNarz img {
        height: 25px;
    }
    .Narzedzia div {
        margin-right: 30px;
    }
    #MobilnyKontakt .Ikona {
        margin-right: 30px;
        width: 55px;
    }
    .mobilnySeparatorKontaktu {
        min-height: 2vh;
    }
    #MobilnyKontakt {
        height: 250px;
        margin-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }
    .NavigationOptions {
        font-size: 25px;
    }
}
@media screen and (max-width: 500px) {
    .UmiejNarz img {
        height: 17px;
    }
    .Zapraszam {
        font-size: .7rem;
    }
}

@media screen and (min-width: 450px) and (max-width: 475px) {
    .LewaAGH .Podsekcja {
        height: 100px;
    }
    .PojemnikEdukacja.IntersekcjaPrawa {
        height: 450px;
    }   
}
@media screen and (max-width: 450px) {
    .NavigationOptions a {
        margin: 15px;
    }
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,1.2);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,1.2);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(2.8, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(2.8, 1) rotate(120deg);
    }
    .Programista {
        height: 65%;
    }

    .PojemnikZamknij img {
        width: 55px;
        height: 55px;
    }
    #MobilnyKontakt .Ikona {
        margin-right: 25px;
        width: 50px;
    }
    #MobilnyKontakt {
        height: 250px;
        margin-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .NavigationOptions {
        font-size: 20px;
    }
    .Header, .Zawartosc {
        width: 100%;
        padding-left: 22px;
        padding-right: 22px;
    }
}
@media screen and (max-width: 420px) {
    .LewaAGH .Podsekcja {
        height: 100px;
    }
    .PojemnikEdukacja.IntersekcjaPrawa {
        height: 500px;
    }   
}
@media screen and (max-width: 400px) {
    .PojemnikEdukacja.IntersekcjaPrawa {
        height: 500px;
    }
}


/* scaling orbits */
@media screen and (max-width: 350px) {
    .n0, .n1, .n2, .n3, .n4, .n5, .n6, .n7   {
        transform: rotate(120deg) scale(0.444,1);
    }
    .n8, .n9, .n10, .n11, .n12 {
        transform: rotate(240deg) scale(0.444,1);
    }
    .PojemOrbitaUmiejetnosci .ElementOrbityWnetrze {
        transform: scale(2.4, 1) rotate(240deg);
    }
    .PojemOrbitaNarzedzia .ElementOrbityWnetrze {
        transform: scale(2.4, 1) rotate(120deg);
    }
    .Programista {
        height: 50%;
    }
    .UmiejNarz img {
        height: 20px;
    }
    .GlowneTechnologie img {
        margin-right: 8px;
    }
    .NavigationOptions {
        font-size: 15px;
    }
    #MobilnyKontakt .Ikona {
        margin-right: 15px;
        width: 40px;
    }
}